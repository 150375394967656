import { Route, Switch, useLocation } from "react-router-dom";
//google Analytics
import ReactGA from "react-ga";

import { lazy, Suspense, useEffect } from "react";
// import from Themes component
import { ThemeProvider } from "styled-components";
// Components =============================================================
// import AboutPage from "./components/AboutPage";
// import BlogPage from "./components/BlogPage";
// import Main from "./components/Main";
// import MySkillsPage from "./components/MySkillsPage";
// import WorkPage from "./components/WorkPage";
// import SoundBox from "./subComponents/SoundBox";
import { lightTheme } from "./components/Themes";
//  ======================================================================= darkTheme
import { AnimatePresence } from "framer-motion";
//  ======================================================================= darkTheme
// import from globalStyles files
import GlobalStyles from "./globalStyles";
import Loading from "./subComponents/Loading";
//  ======================================================================= Components
const Main = lazy(() => import("./components/Main"));
const AboutPage = lazy(() => import("./components/AboutPage"));
const MySkillsPage = lazy(() => import("./components/MySkillsPage"));
const BlogPage = lazy(() => import("./components/BlogPage"));
const WorkPage = lazy(() => import("./components/WorkPage"));
const SoundBox = lazy(() => import("./subComponents/SoundBox"));

////////////////////////////////////////////////////////////////////////////

//initialize google analytics with enviroment variable
// ReactGA.initialize("UA-217659469-1");

ReactGA.initialize(process.env.REACT_APP_PUBLIC_GA_ID);

function App() {
  //google Analytics with react-ga in useEffect process.env.NEXT_PUBLIC_GA_ID
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  // useEffect(() => {
  //   console.log(window.location.pathname);
  // });

  // triggered events, useGAEventsTracker
  // const useGAEventsTracker = (category = "Event Category") => {
  //   const trackEvent = (action = "action", label = "label") => {
  //     ReactGA.event(category, action, label);
  //     return trackEvent;
  //   };

  //   //track an event
  //   const eventTracker = trackEvent("Link triggered");
  //   console.log(eventTracker);
  // };

  //  ======================================================================= darkTheme

  //useLocation hook
  const location = useLocation();

  return (
    <>
      {/* globalStyles here */}
      <GlobalStyles />
      {/* Dark and Light Theme */}
      <ThemeProvider theme={lightTheme}>
        <Suspense fallback={<Loading />}>
          {/* sound component */}
          <SoundBox />

          {/* for frame-motion animation on page change */}
          <AnimatePresence exitBeforeEnter>
            {/* <Routes location={location} key={location.pathname}> */}
            <Switch location={location} key={location.pathname}>
              {/* Routes here */}
              {/* <Route exact path="/" element={<Main />} /> */}
              <Route exact path="/" component={Main} />
              <Route exact path="/about" component={AboutPage} />
              <Route exact path="/work" component={WorkPage} />
//               <Route exact path="/blog" component={BlogPage} />
              <Route exact path="/skills" component={MySkillsPage} />
            </Switch>
            {/* </Routes> */}
          </AnimatePresence>
        </Suspense>
      </ThemeProvider>
    </>
  );
}

export default App;
