//Dark and Light Theme variables
//from Text file get the styles there

//light mode
export const lightTheme = {
  body: "#FCF6F4",
  text: "#000000",
  fontFamily: '"Source Sans Pro", sans-serif',
  bodyRgb: "252, 246, 244",
  textRgb: "0, 0, 0",
};

//dark mode
export const darkTheme = {
  body: "#000000",
  text: "#FCF6F4",
  fontFamily: '"Source Sans Pro", sans-serif',
  textRgb: "252, 246, 244",
  bodyRgb: "0, 0, 0",
};

// You can also use these breakpoints after importing it and use it as breakpoints.sm
export const breakpoints = {
  xm: 20,
  sm: 25, //em
  md: 30,
  lg: 45,
  xl: 60,
  xxl: 75,
};

export const mediaQueries = (key) => {
  return (style) => `@media (max-width: ${key}em) { ${style} }`;
};
